import { Helmet } from 'react-helmet-async';

const HelpSection = () => (
  <>
    <Helmet>
      {/*<title>¿Cómo te puedo ayudar? - Germán Hernández Mairal</title>*/}
      <meta
        name="description"
        content="Descubre cómo Germán Hernández Mairal puede ayudarte a ser más productivo, potenciar tu página web, hacer crecer tu canal de YouTube y construir buenos hábitos."
      />
      <meta
        name="keywords"
        content="ayuda, productividad, desarrollo web, YouTube, hábitos, Germán Hernández"
      />
      <meta name="author" content="Germán Hernández Mairal" />
    </Helmet>
  <section id="help-section" className="bg-white p-8 mt-14 mb-20 sm-xs:p-4 sm-xs:mt-10 sm-xs:mb-16 xs:p-3 xs:mt-8 xs:mb-14 md-xs:p-6 md-xs:mt-12 md-xs:mb-18 sm:p-8 sm:mt-14 sm:mb-20 md:p-10 md:mt-16 md:mb-24 lg:p-12 lg:mt-18 lg:mb-28 xl:p-16 xl:mt-20 xl:mb-32"> {/* Añadir mt-16 para más espacio respecto a WelcomeSection */}
    {/* Contenedor con max-w-[90%] para igualar el ancho */}
    <div className="max-w-[90%] mx-auto">
      {/* Aumentar el tamaño del h2 y añadir más espacio abajo */}
      <h2 className="text-[1.4853rem] md:text-[1.9rem] sm-xs:text-[1.3rem] xs:text-[1.1rem] md-xs:text-[1.5rem] sm:text-[1.7rem] lg:text-[2rem] xl:text-[4.5rem] font-montserrat mb-16 sm-xs:mb-12 xs:mb-10 md-xs:mb-14 sm:mb-16 md:mb-18 lg:mb-20 xl:mb-32">¿Cómo te puedo <span className="relative inline-block text-[#003366] before:absolute before:left-0 before:bottom-[2px] before:w-full before:h-[2px] before:bg-[#ffc107]">ayudar</span>?</h2>

      {/* Distribuir los divs en 4 columnas en pantallas medianas y agregar color de fondo complementario */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {/*<a href="/product-digital" className="block transform transition duration-300 hover:scale-105 hover:bg-blue-300">*/}
        <a href="/youtube" className="block transform transition duration-300 hover:scale-105 hover:bg-blue-300">
          <div className="p-4 bg-blue-200 h-48 sm-xs:h-44 xs:h-40 md-xs:h-42 sm:h-48 md:h-56 lg:h-64 xl:h-80 flex flex-col justify-center items-center text-center rounded-lg">
            <span className="text-4xl sm-xs:text-3xl xs:text-2xl md-xs:text-[2.5rem] sm:text-[3rem] md:text-[3.5rem] lg:text-[4rem] xl:text-[5rem] mb-4 md:mb-8 lg:mb-10 xl:mb-16 xl:-mt-7">🕒</span>
            <h3 className="text-lg sm-xs:text-base xs:text-sm md-xs:text-md sm:text-lg md:text-xl lg:text-2xl xl:text-[2.5rem] font-montserrat">Sé más <span className="relative inline-block text-[#003366] before:absolute before:left-0 before:bottom-[2px] before:w-full before:h-[2px] before:bg-[#ffc107]">productivo</span></h3>
          </div>
        </a>
        <a href="/services" className="block transform transition duration-300 hover:scale-105 hover:bg-blue-300"> {/* Añadir enlace y efectos de hover */}
          <div className="p-4 bg-blue-200 h-48 sm-xs:h-44 xs:h-40 md-xs:h-42 sm:h-48 md:h-56 lg:h-64 xl:h-80 flex flex-col justify-center items-center text-center rounded-lg">
            <span className="text-4xl sm-xs:text-3xl xs:text-2xl md-xs:text-[2.5rem] sm:text-[3rem] md:text-[3.5rem] lg:text-[4rem] xl:text-[5rem] mb-4 md:mb-8 lg:mb-10 xl:mb-16">💻</span>
            <h3 className="text-lg sm-xs:text-base xs:text-sm md-xs:text-md sm:text-lg md:text-xl lg:text-2xl xl:text-[2.5rem] font-montserrat">
            Potencia tu página <span className="xl:block xl:mt-3 relative inline-block text-[#003366] before:absolute before:left-0 before:bottom-[2px] before:w-full before:h-[2px] before:bg-[#ffc107]"> web</span>
            </h3>
            </div>
        </a>
        <a href="/services" className="block transform transition duration-300 hover:scale-105 hover:bg-blue-300"> {/* Añadir enlace y efectos de hover */}
          <div className="p-4 bg-blue-200 h-48 sm-xs:h-44 xs:h-40 md-xs:h-42 sm:h-48 md:h-56 lg:h-64 xl:h-80 flex flex-col justify-center items-center text-center rounded-lg">
            <span className="text-4xl sm-xs:text-3xl xs:text-2xl md-xs:text-[2.5rem] sm:text-[3rem] md:text-[3.5rem] lg:text-[4rem] xl:text-[5rem] mb-4 md:mb-8 lg:mb-10 xl:mb-16">📹</span>
            <h3 className="text-lg sm-xs:text-base xs:text-sm md-xs:text-md sm:text-lg md:text-xl lg:text-2xl xl:text-[2.5rem] font-montserrat">Crece un canal de <span className="xl:block xl:mt-3 relative inline-block text-[#003366] before:absolute before:left-0 before:bottom-[2px] before:w-full before:h-[2px] before:bg-[#ffc107]"> YouTube</span></h3>
          </div>
        </a>
        <a href="/youtube" className="block transform transition duration-300 hover:scale-105 hover:bg-blue-300"> {/* Añadir enlace y efectos de hover */}
          <div className="p-4 bg-blue-200 h-48 sm-xs:h-44 xs:h-40 md-xs:h-42 sm:h-48 md:h-56 lg:h-64 xl:h-80 flex flex-col justify-center items-center text-center rounded-lg">
            <span className="text-4xl sm-xs:text-3xl xs:text-2xl md-xs:text-[2.5rem] sm:text-[3rem] md:text-[3.5rem] lg:text-[4rem] xl:text-[5rem] mb-4 md:mb-8 lg:mb-10 xl:mb-16">🔄</span>
            <h3 className="text-lg sm-xs:text-base xs:text-sm md-xs:text-md sm:text-lg md:text-xl lg:text-2xl xl:text-[2.5rem] font-montserrat">Construye buenos <span className="xl:block xl:mt-3 relative inline-block text-[#003366] before:absolute before:left-0 before:bottom-[2px] before:w-full before:h-[2px] before:bg-[#ffc107]"> hábitos</span></h3>
          </div>
        </a>
      </div>
    </div>
  </section>
  </>
);

export default HelpSection;