import { Helmet } from 'react-helmet-async';

const AboutMeSection = () => (
  <>
  {/* Helmet para gestionar SEO */}
  <Helmet>
      <title>Sobre Mí - Germán Hernández Mairal</title>
      <meta
        name="description"
        content="Conoce más sobre Germán Hernández Mairal, desarrollador web y YouTuber apasionado por la productividad y el desarrollo personal."
      />
      <meta
        name="keywords"
        content="Germán Hernández, Desarrollo Web, YouTube, Productividad, Desarrollo Personal"
      />
      <meta name="author" content="Germán Hernández Mairal" />
  </Helmet>
  <section className="flex flex-col justify-center items-center p-8 bg-blue-100 min-h-[90vh] xs:p-3 sm-xs:p-4 md-xs:p-5 sm:p-6 md:p-7 lg:p-8 xl:p-10">
    <div className="max-w-[90%] mx-auto flex flex-col md:flex-row items-center justify-center md:justify-between xs:flex-col xs:mt-14 sm-xs:mt-0 md-xs:mt-10 sm:mt-12 md:mt-14 lg:mt-16 xl:mt-20">
      {/* Contenedor de texto (a la izquierda) */}
      <div className="text-center md:text-left flex flex-col items-center md:items-start -mt-20 xs:-mt-10 xs:w-full sm-xs:mt-10 md-xs:w-[90%] md-xs:mt-5 sm:w-[85%] sm:mt-6 md:w-[75%] md:mt-8 lg:w-[45%] lg:mt-10 xl:w-[40%] xl:mt-12"> {/* Márgen superior negativo */}
        {/* Título Principal */}
        <h2 className="text-[1.983rem] md:text-[2.9715rem] xs:text-[1.5rem] sm-xs:text-[1.7rem] sm:text-[1.8rem] lg:text-[2.2rem] xl:text-[2.5rem] font-montserrat mb-4 xs:mb-2 sm-xs:mb-2 md-xs:mb-3 sm:mb-4 md:mb-5 lg:mb-6 xl:mb-8 mt-2">
        <span className="bg-[#ffc107] text-[#003366] px-2">Sobre Mí</span>
        </h2>

        {/* Introducción Breve */}
        <p className="mt-4 text-[0.9915rem] sm-xs:text-[0.9rem] xs:text-[0.85rem] md-xs:text-[0.95rem] sm:text-[1rem] md:text-[1.1rem] lg:text-[1.2rem] xl:text-[1.3rem] font-montserrat mb-6 xs:mb-3 sm-xs:mb-3 md-xs:mb-4 sm:mb-4 md:mb-5 lg:mb-6 xl:mb-7">
          Soy Germán, graduado en Desarrollo de Aplicaciones Web y Móviles, YouTuber en crecimiento, y apasionado por la productividad y los hábitos que mejoran el bienestar personal y profesional.
        </p>
        <p className="text-lg font-montserrat mb-4 xs:text-[0.85rem] xs:mb-2 sm-xs:text-[0.9rem] sm-xs:mb-3 md-xs:text-[0.95rem] md-xs:mb-4 sm:text-[1rem] sm:mb-4 md:text-[1.1rem] lg:text-[1.2rem] xl:text-[1.3rem] md:mb-5 lg:mb-6 xl:mb-7">
          Desde <span className="relative inline-block text-[#003366] before:absolute before:left-0 before:bottom-[2px] before:w-full before:h-[2px] before:bg-[#ffc107]">2019</span>, he estado <span className="relative inline-block text-[#003366] before:absolute before:left-0 before:bottom-[2px] before:w-full before:h-[2px] before:bg-[#ffc107]">comprometido</span> en <span className="relative inline-block text-[#003366] before:absolute before:left-0 before:bottom-[2px] before:w-full before:h-[2px] before:bg-[#ffc107]">desarrollar mis habilidades</span> personales y profesionales.
        </p>
        {/* Flecha indicando que hay más contenido */}
        <div className="mt-8 xs:mt-4 sm-xs:mt-5 md-xs:mt-6 sm:mt-7 md:mt-8 lg:mt-9 xl:mt-10 flex justify-center w-full">
            <a href="#bio-section" onClick={(e) => smoothScroll(e)}>
              <svg className="w-8 h-8 xs:w-6 xs:h-6 sm-xs:w-7 sm-xs:h-7 md-xs:w-7 md-xs:h-7 sm:w-8 sm:h-8 md:w-9 md:h-9 lg:w-10 lg:h-10 xl:w-12 xl:h-12 animate-bounce" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </a>
        </div>
      </div>

      {/* Imagen (a la derecha) */}
      <img
        src="/imgs/Germán-Programando.webp"
        alt="Germán trabajando"
        className="w-3/5 md:w-3/6 xs:w-[80%] sm-xs:w-[75%] md-xs:w-[70%] sm:w-[65%] lg:w-[50%] xl:w-[40%] rounded-lg border-2 border-gray-300 shadow-lg xs:mt-4 xs:mb-6 sm-xs:mt-6 sm-xs:mb-8 md-xs:mt-7 md-xs:mb-9 sm:mt-8 sm:mb-10 md:mt-9 md:mb-10 lg:mt-10 lg:mb-12 xl:mt-12 xl:mb-14"
        loading="lazy" // Lazy loading para optimizar la carga
      />
    </div>
  </section>
  </>
);

const smoothScroll = (e) => {
  e.preventDefault();
  const target = document.querySelector(e.currentTarget.getAttribute("href"));
  window.scrollTo({
    top: target.offsetTop,
    behavior: "smooth",
  });
};
export default AboutMeSection;
