import React from 'react';

const ProductDigital = () => {
  return (
    <section className="flex flex-col justify-center items-center p-8 bg-white min-h-[80vh]">
      <div className="max-w-[90%] mx-auto text-center">
        {/* Header */}
        <h2 className="text-[1.983rem] md:text-[2.9715rem] font-montserrat mb-6">Producto Digital</h2>

        {/* Descripción */}
        <p className="text-lg font-montserrat mb-6">
          Mejora tu productividad con nuestro exclusivo producto digital. Obtén herramientas prácticas y resultados medibles para alcanzar tus objetivos.
        </p>

        {/* Resultados Esperados */}
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg mb-8">
          <h3 className="text-xl font-semibold mb-4">¿Qué puedes esperar?</h3>
          <ul className="text-left">
            <li>✔️ Aumenta tu productividad diaria</li>
            <li>✔️ Mejora tu organización personal</li>
            <li>✔️ Aprende a gestionar tu tiempo eficientemente</li>
          </ul>
        </div>

        {/* FAQs */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-lg mb-8">
          <h3 className="text-xl font-semibold mb-4">Preguntas Frecuentes</h3>
          <div className="text-left">
            <p><strong>¿Cómo funciona el producto?</strong> <br /> Recibirás acceso inmediato a las herramientas tras la compra.</p>
            <p><strong>¿Es adecuado para mí?</strong> <br /> Sí, si buscas mejorar tu productividad y organización personal.</p>
          </div>
        </div>

        {/* Botón de Compra */}
        <button className="bg-blue-500 text-white px-6 py-3 rounded-lg font-montserrat hover:bg-blue-600 transition duration-300">
          Compra Ahora
        </button>
      </div>
    </section>
  );
};

export default ProductDigital;