import { Helmet } from 'react-helmet-async';

const MissionSection = () => (
  <>
    <Helmet>
      {/*<title>Mi Misión - Germán Hernández Mairal</title>*/}
      <meta
        name="description"
        content="Mi misión es ayudar a emprendedores, empresarios y creadores de contenido a mejorar su productividad, optimizar su presencia online y fortalecer su marca personal con estrategias probadas y personalizadas."
      />
      <meta
        name="keywords"
        content="misión, Germán Hernández, productividad, marca personal, presencia online, estrategias"
      />
      <meta name="author" content="Germán Hernández Mairal" />
    </Helmet>
  <section className="flex flex-col justify-center items-center p-8 bg-blue-200 min-h-[80vh] -mb-4 xs:p-3 xs:pb-6 xs:-mb-16 sm-xs:p-4 sm-xs:pb-6 md-xs:p-5 md-xs:-mb-16 sm:p-6 sm:pb-8 sm:-mb-16 md:p-7 md:pb-10 md:-mb-13 lg:p-8 lg:pb-12 lg:-mb-16 xl:p-10 xl:pb-14 xl:-mb-18">
    <div className="max-w-[90%] mx-auto text-center">
      {/* Título Principal */}
      <h2 className="text-[1.983rem] xs:text-[1.5rem] sm-xs:text-[1.7rem] md-xs:text-[1.6rem] sm:text-[1.8rem] md:text-[2rem] lg:text-[2.2rem] xl:text-[2.5rem] font-montserrat mb-6 xs:mb-6 sm-xs:mb-6 md-xs:mb-7 sm:mb-8 md:mb-9 lg:mb-10 xl:mb-12"><span className="bg-[#ffc107] text-[#003366] px-2">Mi Misión</span></h2>

      {/* Texto de Misión */}
      <p className="text-lg font-montserrat mb-6 xs:text-[0.85rem] xs:mb-11 sm-xs:text-[0.9rem] sm-xs:mb-11 md-xs:text-[0.95rem] md-xs:mb-12 sm:text-[1rem] sm:mb-12 md:text-[1.1rem] lg:text-[1.2rem] xl:text-[1.3rem] md:mb-16 lg:mb-18 xl:mb-20">
        Mi misión es ayudar a emprendedores, empresarios y creadores de contenido a mejorar su <span className="relative inline-block text-[#003366] before:absolute before:left-0 before:bottom-[2px] before:w-full before:h-[2px] before:bg-[#ffc107]">productividad</span>, optimizar su <span className="relative inline-block text-[#003366] before:absolute before:left-0 before:bottom-[2px] before:w-full before:h-[2px] before:bg-[#ffc107]">presencia online</span> y potenciar su <span className="relative inline-block text-[#003366] before:absolute before:left-0 before:bottom-[2px] before:w-full before:h-[2px] before:bg-[#ffc107]">marca personal</span> a través de <span className="bg-custom-orange text-white px-1 text-shadow-custom-dark-blue">estrategias probadas y personalizadas.</span>
      </p>

      {/* Llamada a la Acción */}
      <a href="/contact" className="bg-blue-500 text-white px-6 py-2 rounded-lg font-montserrat hover:bg-blue-600 transition duration-300 xs:px-3 xs:py-3 xs:text-[0.9rem] xs:text-center sm-xs:px-4 sm-xs:py-3 sm-xs:text-[0.9rem] md-xs:px-5 md-xs:py-4 md-xs:text-[1rem] sm:px-6 sm:py-4 sm:text-[1rem] md:px-7 md:py-5 md:text-[1.2rem] lg:px-8 lg:py-6 lg:text-[1.3rem] xl:px-10 xl:py-7 xl:text-[1.4rem] whitespace-nowrap">
        ¿Necesitas ayuda? ¡Contáctame!
      </a>
    </div>
  </section>
  </>
);
export default MissionSection;