import React from 'react';
import { Helmet } from 'react-helmet-async';

const FinalCallToAction = () => {
  return (
    <>
      <Helmet>
        {/*<title>Suscríbete - Germán Hernández Mairal</title>*/}
        <meta
          name="description"
          content="No te pierdas el próximo contenido de Germán Hernández Mairal. Suscríbete a su canal de YouTube para más videos sobre productividad, desarrollo personal y programación."
        />
        <meta
          name="keywords"
          content="Germán Hernández, suscripción YouTube, productividad, desarrollo personal, programación"
        />
        <meta name="author" content="Germán Hernández Mairal" />
      </Helmet>
    <div className="text-center mt-6 mb-5 xs:px-4 xs:-mt-6 md:-mt-6 lg:mt-0 md:mb-8 lg:mb-12 xl:mb-16">
      <p className="text-[1.2rem] xs:text-[0.9rem] md:text-[1rem] lg:text-[1.2rem] xl:text-[1.8rem] xl:mt-4 font-montserrat text-gray-800 mb-6 xs:mb-8 md:mb-8 lg:mb-10 xl:mb-16">
      ¿Te llama la atención? < br /> <span className="relative inline-block text-[#003366] before:absolute before:left-0 before:bottom-[2px] before:w-full before:h-[2px] before:bg-[#ffc107]">Suscríbete a mi canal</span> para no perderte los próximos videos.
      </p>
      <a
        href="https://www.youtube.com/@germanhernandezmairal"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-[#003366] text-white px-6 py-2 md:py-3 lg:py-4 xl:py-5 lg:px-8 xl:px-10 rounded-lg hover:bg-blue-600 transition duration-300 xl:text-[2rem] font-montserrat text-[1.1rem] md:text-base lg:text-lg xl:mb-12 xl:text-xl"
      >
        Suscribirse
      </a>
    </div>
    </>
  );
};

export default FinalCallToAction;