import React from 'react';

const Consulting = () => {
  return (
    <section className="flex flex-col justify-center items-center p-8 bg-white min-h-[80vh]">
      <div className="max-w-[90%] mx-auto text-center">
        {/* Header */}
        <h2 className="text-[1.983rem] md:text-[2.9715rem] font-montserrat mb-6">Consultoría 1 a 1</h2>

        {/* Descripción */}
        <p className="text-lg font-montserrat mb-6">
          Te ayudo a transformar tus hábitos y mejorar tu rendimiento personal y profesional mediante una consultoría personalizada 1 a 1.
        </p>

        {/* Beneficios y Resultados */}
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg mb-8">
          <h3 className="text-xl font-semibold mb-4">Beneficios de la Consultoría</h3>
          <ul className="text-left">
            <li>✔️ Plan personalizado de mejora de hábitos</li>
            <li>✔️ Acompañamiento en cada paso</li>
            <li>✔️ Resultados visibles en tu día a día</li>
          </ul>
        </div>

        {/* FAQs */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-lg mb-8">
          <h3 className="text-xl font-semibold mb-4">Preguntas Frecuentes</h3>
          <div className="text-left">
            <p><strong>¿Cómo se realiza la consultoría?</strong> <br /> A través de videollamadas donde trabajaremos en tus hábitos.</p>
            <p><strong>¿Cuánto dura cada sesión?</strong> <br /> Cada sesión tiene una duración de 1 hora.</p>
          </div>
        </div>

        {/* Botón de Agendar */}
        <button className="bg-blue-500 text-white px-6 py-3 rounded-lg font-montserrat hover:bg-blue-600 transition duration-300">
          Agenda tu Llamada
        </button>
      </div>
    </section>
  );
};

export default Consulting;