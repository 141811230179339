import React from 'react';
import { Helmet } from 'react-helmet-async';
import LetsTalk from './LetsTalkSection';
import Contact from './ContactSection';
import ContactInfo from './ContactInfoSection'; // Importamos la nueva sección

const MainContactLayoutSection = () => {
  return (
    <>
      <Helmet>
        <title>Contacto - Germán Hernández Mairal</title>
        <meta
          name="description"
          content="Contáctame para hablar sobre tu próximo proyecto o consulta. Explora mis opciones de contacto, incluyendo formulario y vías alternativas de comunicación."
        />
        <meta
          name="keywords"
          content="Germán Hernández, contacto, formulario de contacto, información de contacto, desarrollo web, productividad"
        />
        <meta name="author" content="Germán Hernández Mairal" />
      </Helmet>
    <section className="flex flex-col justify-start items-start bg-blue-100">
      {/* Sección Hablemos y Formulario de Contacto en horizontal */}
      <div className="flex flex-col md:flex-row w-full justify-between space-y-8 md:space-y-0 min-h-[80vh]">
        {/* Sección Hablemos */}
        <div className="w-full md:w-1/2">
          <LetsTalk />
        </div>

        {/* Sección Formulario de Contacto */}
        <div className="w-full md:w-1/2">
          <Contact />
        </div>
      </div>

      {/* Nueva Sección de Información de Contacto Alternativa */}
      <div className="w-full bg-white min-h-[80vh] flex items-center">
        <ContactInfo />
      </div>
    </section>
    </>
  );
};

export default MainContactLayoutSection;