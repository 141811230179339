import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

// Definir claves de reCAPTCHA dependiendo del entorno
const RECAPTCHA_SITE_KEY = process.env.NODE_ENV === 'production'
  ? '6LeRj2gqAAAAAL24SW-EIzCagRMOeiWkx6YCcx7u' // Clave para producción
  : '6LdXnGkqAAAAAGjenUTzx2a4CRr7A-2NAqjnVlKJ';  // Clave para localhost/desarrollo


const BACKEND_URL = process.env.NODE_ENV === 'production'
? 'https://pagina-web-marca-personal-backend.vercel.app/send-email' // Reemplaza con la URL real de tu backend
: 'http://localhost:3001/send-email';  // Mantén localhost para desarrollo

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false); // Para el mensaje de confirmación
  const [error, setError] = useState(''); // Para manejar errores
  const [rows, setRows] = useState(6); // Estado para el número de filas
  const [recaptchaToken, setRecaptchaToken] = useState(''); // Token generado por reCAPTCHA v3

    // Efecto para ajustar el valor de rows según el tamaño de la pantalla y cargar reCAPTCHA
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1280) {
        setRows(10);
      } else {
        setRows(6);
      }
    };

    // Ajustar el valor de rows al cargar y al cambiar el tamaño de la ventana
    handleResize();
    window.addEventListener('resize', handleResize);

    // Función para cargar el script de reCAPTCHA dinámicamente
    const loadRecaptchaScript = () => {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        if (window.grecaptcha) {
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' }).then((token) => {
              setRecaptchaToken(token); // Guardar el token generado
            });
          });
        } else {
          setError('reCAPTCHA no se ha cargado. Intenta recargar la página.');
        }
      };

      script.onerror = () => {
        setError('Error al cargar reCAPTCHA. Intenta de nuevo más tarde.');
      };
    };

    // Cargar el script de reCAPTCHA si no está presente
    if (!window.grecaptcha) {
      loadRecaptchaScript();
    } else {
      // Si ya está cargado, ejecutamos reCAPTCHA directamente
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' }).then((token) => {
          setRecaptchaToken(token); // Guardar el token generado
        });
      });
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Limpiar el estado de error en cada envío

    if (!recaptchaToken) {
      setError('No se ha podido verificar reCAPTCHA. Intenta de nuevo.');
      return;
    }

    try {

      // Generar un nuevo token antes de cada envío
      const newToken = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' });
      setRecaptchaToken(newToken);

      const response = await fetch(`${BACKEND_URL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          subject,
          message,
          recaptchaToken: newToken, // Enviar el token de reCAPTCHA al backend
        }),
      });

      if (!response.ok) {
        const result = await response.json();

        // Detectar errores específicos y mostrar mensajes personalizados
        if (response.status === 429) {
          throw new Error('Espera un minuto antes de enviar otro mensaje.');
        } else if (response.status === 400) {
          throw new Error(result.message || 'Verificación fallida, revisa el reCAPTCHA.');
        } else {
          throw new Error(result.message || 'Error al enviar el mensaje');
        }
      }
  
      console.log('Correo enviado correctamente');
      setFormSubmitted(true);
      setName(''); // Limpiar los campos después de enviar el formulario
      setEmail('');
      setSubject('');
      setMessage('');
    } catch (e) {
      console.error('Error al agregar el documento: ', e);
      setError('Espera un minuto antes de enviar otro mensaje por favor.'); // Mensaje de error
    }
  };

  return (
    <>
      <Helmet>
        {/*<title>Contáctame - Germán Hernández Mairal</title>*/}
        <meta
          name="description"
          content="Formulario de contacto para Germán Hernández Mairal. Envíame un mensaje para hablar sobre tu próximo proyecto o cualquier consulta."
        />
        <meta
          name="keywords"
          content="Contacto, Germán Hernández, mensaje, proyectos, desarrollo web, formulario"
        />
        <meta name="author" content="Germán Hernández Mairal" />
      </Helmet>
    <section id="contactForm" className="flex flex-col justify-center p-8 md-xs:pt-0 sm:pb-20 bg-blue-100 min-h-[30vh]">
      <div className="w-full max-w-[1200px] xl:max-w-[1400px] mx-auto mt-[60px] md:mt-[110px] sm:mt-[20px] md-xs:mt-14 sm-xs:-mt-6 xs:-mt-6">

        {formSubmitted && (
          <p className="text-green-500 mb-6 font-montserrat">
            ¡Gracias por contactarme! Te responderé pronto.
          </p>
        )}

        {error && (
          <p className="text-red-500 mb-6 font-montserrat">
            {error}
          </p>
        )}

        <form onSubmit={handleSubmit} className="w-full max-w-lg xl:max-w-4xl xl:min-h-[60vh] mx-auto bg-white xl:p-10 xl:py-12 p-6 rounded-lg shadow-lg">
          {/* Input para Nombre */}
          <div className="mb-4 xl:mb-6">
            <input
              type="text"
              placeholder="Nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-4 py-2 xl:px-6 xl:py-4 xl:text-[1.25rem] border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {/* Input para Correo */}
          <div className="mb-4 xl:mb-6">
            <input
              type="email"
              placeholder="Correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 xl:px-6 xl:py-4  xl:text-[1.25rem] border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {/* Input para Asunto */}
          <div className="mb-4 xl:mb-6">
            <input
              type="text"
              placeholder="Asunto"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="w-full px-4 py-2 xl:px-6 xl:py-4  xl:text-[1.25rem] border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Textarea para Mensaje */}
          <div className="mb-4 xl:mb-6">
            <textarea
              placeholder="Mensaje"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full px-4 py-2 xl:px-6 xl:py-4 xl:text-[1.25rem] border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows={rows} // Cambia dinámicamente según el tamaño de la pantalla
              required
            />
          </div>

          {/* Aquí agregas el widget de reCAPTCHA
          <div className="g-recaptcha" data-sitekey="6LeRj2gqAAAAAL24SW-EIzCagRMOeiWkx6YCcx7u"></div> */}
          {/* Aquí agregas el widget de reCAPTCHA */}
          {/*<div id="recaptcha-container"></div> */}

          {/* Botón de Enviar */}
          <button
            type="submit"
            className="bg-blue-500 text-white px-6 py-2 xl:py-4 xl:text-[1.5rem] rounded-lg font-montserrat hover:bg-blue-600 transition duration-300 w-full"
          >
            Enviar Mensaje
          </button>
        </form>
      </div>
    </section>
    </>
  );
}

export default Contact;