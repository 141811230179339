import React from 'react';
import { Helmet } from 'react-helmet-async';

const ContactInfo = () => {
  // Función para hacer smooth scroll hacia el formulario de contacto
  const scrollToForm = () => {
    const formSection = document.getElementById('contactForm');
    formSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      {/* Helmet para gestionar SEO */}
      <Helmet>
        {/*<title>Información de Contacto - Germán Hernández Mairal</title>*/}
        <meta
          name="description"
          content="Información de contacto alternativa para Germán Hernández Mairal. Contáctame por correo o envíame un mensaje para hablar sobre tu próximo proyecto."
        />
        <meta
          name="keywords"
          content="Contacto Germán Hernández, correo electrónico, proyectos, desarrollo web, productividad"
        />
        <meta name="author" content="Germán Hernández Mairal" />
      </Helmet>
    <section className="flex flex-col justify-center items-center p-8 bg-white w-full">
      <div className="w-full max-w-[1200px] mx-auto text-left">
        {/* Título Principal */}
        <h2 className="text-[1.983rem] md:text-[2.9715rem] xl:text-[3.5rem] xl:-ml-[515px] xl:mt-[36px] font-montserrat mb-9 text-left -ml-[58px] mt-12 md-xs:ml-[18px] md-xs:-mb-2 sm:mt-16 sm:ml-[28px] md:ml-[48px] lg:-ml-[57px] sm-xs:ml-[22px] xs:ml-[16px]">
          Información de Contacto Alternativa
        </h2>

        {/* Contenedor para centrar el contenido (excepto el header) */}
        <div className="flex flex-col items-center justify-center">
          {/* Correo Electrónico con rectángulo de fondo */}
          <p className="mt-16 text-[1rem] md:text-[1.4865rem] xl:text-[2rem] font-montserrat mb-4 text-center md-xs:mt-20 md-xs:ml-[0px] sm:mt-24 xl:mt-44 xs:mt-10">
            Puedes contactarme directamente a{' '}
            <span className="bg-[#003366] text-[#ffc107] py-1 px-2 text-shadow-custom-light-orange">
              gerhm19@gmail.com
            </span>.
          </p>

          {/* Llamada a la Acción Final con Botón */}
          <p className="mt-16 text-[1rem] md:text-[1.4865rem] xl:text-[2rem] font-montserrat mb-4 text-center md-xs:mt-16 md-xs:ml-[0px] md:max-w-[700px] xl:mt-24">
            Estoy aquí para <span className="relative inline-block text-[#003366] before:absolute before:left-0 before:bottom-[2px] before:w-full before:h-[2px] before:bg-[#ffc107]">ayudarte</span> en tu próximo proyecto.
            <span className="block mt-4">¿Listo para comenzar?</span>
          </p>
          <div className="mt-11 w-full flex justify-center mb-4 md-xs:mt-4 sm:mt-8 xl:mt-16">
            <button
              onClick={scrollToForm}
              className="bg-blue-600 text-white py-2 px-4 xl:py-4 xl:px-8 xl:text-[1.5rem] rounded hover:bg-blue-700 font-montserrat transition-colors"
            >
              ¡Hablemos!
            </button>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default ContactInfo;