import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';


import Header from './components/Header';
import WelcomeSection from './components/WelcomeSection';
import HelpSection from './components/HelpSection';
import Footer from './components/Footer';  // Importa el Footer
import AboutMeSection from './components/AboutMeSection';
import BioSection from './components/BioSection';
import MissionSection from './components/MissionSection';
import ConsultingSection from './components/ConsultingSection';
import ProductDigitalSection from './components/ProductDigitalSection';
import MainContactLayoutSection from './components/MainContactLayoutSection';
import UnderConstructionSection from './components/UnderConstructionSection';
import YouTubePage from './components/YouTubePage';  // Importa la página de YouTube*/

// Carga condicional de componentes con React.lazy
/*const Header = React.lazy(() => import('./components/Header'));
const WelcomeSection = React.lazy(() => import('./components/WelcomeSection'));
const HelpSection = React.lazy(() => import('./components/HelpSection'));
const Footer = React.lazy(() => import('./components/Footer'));
const AboutMeSection = React.lazy(() => import('./components/AboutMeSection'));
const BioSection = React.lazy(() => import('./components/BioSection'));
const MissionSection = React.lazy(() => import('./components/MissionSection'));
const ConsultingSection = React.lazy(() => import('./components/ConsultingSection'));
const ProductDigitalSection = React.lazy(() => import('./components/ProductDigitalSection'));
const MainContactLayoutSection = React.lazy(() => import('./components/MainContactLayoutSection'));
const UnderConstructionSection = React.lazy(() => import('./components/UnderConstructionSection'));
const YouTubePage = React.lazy(() => import('./components/YouTubePage'));*/

ReactGA.initialize('G-XXXXXXXXXX'); // Usa el ID de Medición de Google Analytics aquí.

ReactGA.send("pageview");

function App() {
  return (
    <HelmetProvider>
      <Router>
      <div className="App">
        <Header />
        <Routes>
          {/* Página principal con las secciones */}
          <Route path="/" element={
            <>
              <WelcomeSection />
              <HelpSection />
              <Footer />
            </>
          } />
          {/* Otras rutas */}
          {/*<Route path="/home" element={<Home />} />*/}
          {/*<Route path="/services" element={<><ServicesFISection /><ServicesDetailsSection /><Footer /></>} />*/}
          <Route path="/contact" element={<><MainContactLayoutSection/><Footer /></>} />
          {/*<Route path="/messages" element={<Messages />} />*/}
          <Route path="/about" element={<><AboutMeSection /> <BioSection /> <MissionSection /><Footer /></>} />

           {/* Nuevas rutas para las secciones "en desarrollo" */}
           <Route path="/services" element={<><UnderConstructionSection sectionName="Servicios" /><Footer /></>} />
          <Route path="/portfolio" element={<><UnderConstructionSection sectionName="Portafolio" /><Footer /></>} />

          {/* Ruta de YouTube */}
          <Route path="/youtube" element={<><YouTubePage /><Footer /></>} />  {/* Añadida la ruta de YouTube */}

          {/* Nuevas rutas para los servicios específicos */}
          <Route path="/consulting" element={<ConsultingSection />} />
          <Route path="/product-digital" element={<ProductDigitalSection />} />
        </Routes>
      </div>
    </Router>
    </HelmetProvider> 
  );
}

export default App;
