import React from 'react';
import YouTubeHeader from './YouTubeHeader';
//import LatestYouTubeVideo from './LatestYouTubeVideo';
import VideoGrid from './VideoGrid';
import Testimonials from './Testimonials'; // Opcional
import FinalCallToAction from './FinalCallToAction';
import { Helmet } from 'react-helmet-async';

const YouTubePage = () => {
  return (
    <section className="min-h-[100vh] bg-blue-100 p-0">
      <Helmet>
        <title>Mi Canal de YouTube - Germán Hernández Mairal</title>
        <meta
          name="description"
          content="Explora el canal de YouTube de Germán Hernández Mairal, donde comparte contenido sobre productividad, hábitos y desarrollo personal para inspirarte a mejorar."
        />
        <meta
          name="keywords"
          content="YouTube, Germán Hernández, productividad, hábitos, desarrollo personal, inspiración"
        />
        <meta name="author" content="Germán Hernández Mairal" />
      </Helmet>
      {/* Header de la página */}
      <div className="pt-32 pb-8">
        <YouTubeHeader />
      </div>

      {/* Video Destacado */}
      {/*<div className="pt-8 pb-8">
        <LatestYouTubeVideo />
      </div>*/}

      {/* Grid de Videos */}
      <div className="bg-gray-100 pt-1 pb-1">
        <VideoGrid />
      </div>

      {/* Testimonios */}
      <div className="pt-8 pb-8">
        <Testimonials />
      </div>

      {/* Llamada a la acción final */}
      <div className="pt-8 pb-16 -mb-16">
        <FinalCallToAction />
      </div>
    </section>
  );
};

export default YouTubePage;
