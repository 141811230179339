import React from 'react';
import { Helmet } from 'react-helmet-async';

const Testimonials = () => {
  return (
    <>
      <Helmet>
        {/*<title>Testimonios - Germán Hernández Mairal</title>*/}
        <meta
          name="description"
          content="Descubre lo que dicen otros sobre la ayuda y consejos de Germán Hernández Mairal en productividad, hábitos y motivación."
        />
        <meta
          name="keywords"
          content="testimonios, Germán Hernández, productividad, inspiración, hábitos"
        />
        <meta name="author" content="Germán Hernández Mairal" />
      </Helmet>
    <div className="max-w-[90%] xs:max-w-full mx-auto text-center mb-0 mt-10 xs:mb-10 xs:mt-10 md:mb-14 md:mt-14 lg:mb-10 lg:mt-24 xl:mb-16 xl:mt-32 xs:px-4">
      <h2 className="text-[2rem] xs:text-[1.5rem] md:text-[1.8rem] lg:text-[2rem] xl:text-[3rem] font-montserrat font-semibold text-[#ffc107] mb-20 xs:mb-14 md:mb-16 lg:mb-20 xl:mb-24 text-shadow-custom-dark-blue">Comentarios destacados</h2>
      <p className="text-[#003366] font-bold text-[1rem] xs:text-sm lg:text-lg xl:text-xl xl:text-[1.8rem] font-montserrat mb-4 xs:mb-4 xl:mb-8 md:text-base testimonio">"Muchas gracias por tus <span className="text-[#ffc107]">consejos</span>!!! Eres una fuente de <span className="text-[#ffc107]">inspiración</span>!!!🎉"</p>
      <div className="divider"></div> {/* Divider entre testimonios */}
      <p className="text-[#003366] font-bold text-[1rem] xs:text-sm lg:text-lg xl:text-xl xl:text-[1.8rem] font-montserrat mb-4 xs:mb-4 xl:mb-8 md:text-base testimonio">"Que buenos <span className="text-[#ffc107]">hábitos</span>! Es cierto que leyendo 30 minutos al día se crea un gran hábito a largo plazo."</p>
      <div className="divider"></div> {/* Divider entre testimonios */}
      <p className="text-[#003366] font-bold text-[1rem] xs:text-sm lg:text-lg xl:text-xl xl:text-[1.8rem] font-montserrat mb-4 xs:mb-4 xl:mb-8 md:text-base testimonio">"No sabes lo que me <span className="text-[#ffc107]">motiva</span> cada uno de tus videos! 🧠💪💪"</p>
    </div>
    </>
  );
};

export default Testimonials;
