import React from 'react';
import { Helmet } from 'react-helmet-async';

const YouTubeHeader = () => {
  return (
    <>
      <Helmet>
        {/*<title>Mi Contenido en YouTube - Germán Hernández Mairal</title>*/}
        <meta
          name="description"
          content="Explora el contenido de Germán Hernández en YouTube, donde comparte hábitos, aprendizajes y experiencias para inspirar a otros a mejorar sus vidas."
        />
        <meta
          name="keywords"
          content="YouTube, Germán Hernández, hábitos, aprendizaje, desarrollo personal, inspiración"
        />
        <meta name="author" content="Germán Hernández Mairal" />
      </Helmet>
    <div className="text-center mb-0 xs:px-4 md:px-8 lg:px-16 xl:px-36 xl:mt-10">
      <h1 className="text-[2.5rem] xs:text-[1.5rem] md:text-[2rem] lg:text-[2.5rem] xl:text-[3rem] xs:text-left xs:ml-10 xs:-mt-16 font-montserrat font-bold mb-4 text-left ml-24 md:ml-14 lg:ml-8 xl:ml-6">Mi Contenido en <span className="bg-[#ffc107] text-[#003366] px-2">YouTube</span></h1>
      <p className="text-[1.2rem] xs:text-[0.9rem] md:text-[1rem] lg:text-[1.2rem] xl:text-[1.8rem] font-montserrat mb-20 mt-20 xs:mb-4 xs:mt-8 md:mb-16 md:mt-12 lg:mb-24 lg:mt-16 xl:mb-32 xl:mt-24 max-w-[70%] xs:max-w-[85%] md:max-w-[80%] sm-xs:max-w-[75%] lg:max-w-[75%] xl:max-w-[65%] mx-auto">
        En mi canal de YouTube comparto <span className="bg-custom-orange text-white px-1 text-shadow-custom-dark-blue">hábitos</span>, <span className="bg-custom-orange text-white px-1 text-shadow-custom-dark-blue">aprendizajes</span> y <span className="bg-custom-orange text-white px-1 text-shadow-custom-dark-blue">experiencias</span> para <span className="relative inline-block text-[#003366] before:absolute before:left-0 before:bottom-[2px] before:w-full before:h-[2px] before:bg-[#ffc107]">inspirar</span> a los demás a <span className="relative inline-block text-[#003366] before:absolute before:left-0 before:bottom-[2px] before:w-full before:h-[2px] before:bg-[#ffc107]">mejorar sus vidas</span>.
      </p>
      {/*<a
        href="https://www.youtube.com/@germanhernandezmairal"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition duration-300 font-montserrat text-[1.1rem]"
      >
        Visita mi canal
      </a>*/}
    </div>
    </>
  );
};

export default YouTubeHeader;
