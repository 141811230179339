import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa'; // Iconos para el menú
import { Helmet } from 'react-helmet-async';


const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // Función para alternar la apertura del menú
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <Helmet>
        <title>Inicio - Germán Hernández Mairal</title>
        <meta
          name="description"
          content="Explora los servicios, portafolio, contenido de YouTube y más de Germán Hernández Mairal, enfocado en desarrollo personal, programación y productividad."
        />
        <meta
          name="keywords"
          content="Germán Hernández, servicios, portafolio, YouTube, desarrollo personal, programación, productividad"
        />
        <meta name="author" content="Germán Hernández Mairal" />
      </Helmet>
    <header className="bg-gray-800 text-white p-4 w-full">
      <div className="max-w-[90%] mx-auto flex justify-between items-center">
        {/* Firma a la izquierda */}
        <Link to="/" className="text-xl xl:text-3xl font-signature italic">
          GHM
        </Link>

        {/* Icono de hamburguesa (visible en pantallas menores a 768px) */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-2xl xl:text-4xl focus:outline-none">
            {menuOpen ? <FaTimes /> : <FaBars />} {/* Cambia el icono cuando el menú está abierto/cerrado */}
          </button>
        </div>

        {/* Menú de navegación para pantallas grandes */}
        <nav className="hidden md:flex">
          <ul className="flex space-x-6 xl:space-x-10">
            <li><Link to="/services" className="hover:text-blue-400 font-montserrat xl:text-xl">Servicios</Link></li>
            <li><Link to="/portfolio" className="hover:text-blue-400 font-montserrat xl:text-xl">Portafolio</Link></li>
            <li><Link to="/youtube" className="hover:text-blue-400 font-montserrat xl:text-xl">YouTube</Link></li>
            <li><Link to="/about" className="hover:text-blue-400 font-montserrat xl:text-xl">Quién Soy</Link></li>
            <li><Link to="/contact" className="hover:text-blue-400 font-montserrat xl:text-xl">Contacto</Link></li>
          </ul>
        </nav>

        {/* Menú lateral deslizante desde la derecha */}
        <div className={`fixed top-0 right-0 h-full bg-gray-800 text-white transform ${menuOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out w-3/4 md-xs:w-1/2 sm:w-1/3 sm-xs:w-[45%] xs:w-[50%] md:hidden xl:w-1/4`} style={{ zIndex: 9999 }}>
          {/* Icono de cierre dentro del menú */}
          <button onClick={toggleMenu} className="text-3xl xl:text-5xl absolute top-4 md-xs:right-8 sm:right-11 xs:right-8 focus:outline-none">
            <FaTimes />
          </button>

          <ul className="p-8 space-y-6 mt-10 xl:text-xl"> {/* Añadido mt-10 para dejar espacio para el icono */}
            <li><Link to="/services" onClick={toggleMenu} className="hover:text-blue-400 font-montserrat">Servicios</Link></li>
            <li><Link to="/portfolio" onClick={toggleMenu} className="hover:text-blue-400 font-montserrat">Portafolio</Link></li>
            <li><Link to="/youtube" onClick={toggleMenu} className="hover:text-blue-400 font-montserrat">YouTube</Link></li>
            <li><Link to="/about" onClick={toggleMenu} className="hover:text-blue-400 font-montserrat">Quién Soy</Link></li>
            <li><Link to="/contact" onClick={toggleMenu} className="hover:text-blue-400 font-montserrat">Contacto</Link></li>
          </ul>
        </div>
      </div>
    </header>
  </>
  );
};

export default Header;