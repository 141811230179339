import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

const VideoGrid = () => {
  const [videos, setVideos] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('productividad');
  const [itemsToShow, setItemsToShow] = useState(1); // Controla la cantidad de videos a mostrar
  const API_KEY = 'AIzaSyBRd_8kCzTFwJ0nc9kZhJrjhmdycirn7s0'; // Reemplaza con tu API Key

  // Memoriza los playlists para evitar recrearlos en cada render
  const playlists = useMemo(() => ({
    productividad: 'PLm6JN36t1vNzC0KLk6htDnG9xXrzyO7pd',
    habitos: 'PLm6JN36t1vNzznmS8I8Id9qg4arWxAnbf',
    estiloDeVida: 'PLm6JN36t1vNyj0mFFwVvHCYJoljrU5HZx',
  }), []);

  // Función para formatear los nombres de las categorías
  const formatCategoryName = (category) => {
    switch (category) {
      case 'productividad':
        return 'Productividad 🚀';
      case 'habitos':
        return 'Hábitos 🔥';
      case 'estiloDeVida':
        return 'Estilo de Vida 🌱';
      default:
        return category;
    }
  };

  useEffect(() => {
    const fetchVideosFromPlaylists = async () => {
      try {
        const playlistId = playlists[selectedCategory];
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,status&maxResults=5&playlistId=${playlistId}&key=${API_KEY}`
        );
        const allVideos = response.data.items.filter(video => video.status.privacyStatus === 'public');
        setVideos(allVideos);
      } catch (error) {
        console.error('Error al obtener los videos de las listas de reproducción:', error);
      }
    };

    fetchVideosFromPlaylists();
  }, [API_KEY, playlists, selectedCategory]);

  useEffect(() => {
    // Función para ajustar el número de videos a mostrar basado en el tamaño de la pantalla
    const handleResize = () => {
      if (window.innerWidth < 576) { // 'xs': Menos de 576px, mostrar 1 video
        setItemsToShow(1);
      } else if (window.innerWidth >= 576 && window.innerWidth <= 768) { // Entre 576px y 768px, mostrar 2 videos
        setItemsToShow(2);
      } else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
        setItemsToShow(Math.min(videos.length, 3));
      } else if (window.innerWidth > 1024 && window.innerWidth < 1440) {
        setItemsToShow(Math.min(videos.length, 4));
      } else {
        setItemsToShow(Math.min(videos.length, 4));
      }
    };

    // Ejecutar la función en el montaje inicial y al cambiar el tamaño de la ventana
    window.addEventListener('resize', handleResize);
    handleResize(); // Ejecutarlo al montarse

    // Eliminar el evento en el desmontaje
    return () => window.removeEventListener('resize', handleResize);
  }, [videos.length]);

  const [startIndex, setStartIndex] = useState(0);

  // Función para ir al siguiente video
  const nextSlide = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  // Función para ir al video anterior
  const prevSlide = () => {
    setStartIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length);
  };

  // Obtener los videos que se deben mostrar en la vista actual
  const displayedVideos = videos.slice(startIndex, startIndex + itemsToShow);

  // Si hay menos videos disponibles que el total a mostrar, repetirlos de manera circular
  if (displayedVideos.length < itemsToShow) {
    const remaining = itemsToShow - displayedVideos.length;
    displayedVideos.push(...videos.slice(0, remaining));
  }

  return (
    <>
      <Helmet>
        {/*<title>Galería de Videos - Germán Hernández Mairal</title>*/}
        <meta
          name="description"
          content="Explora la galería de videos de Germán Hernández sobre productividad, hábitos y estilo de vida. Encuentra el contenido que te ayudará a mejorar."
        />
        <meta
          name="keywords"
          content="videos, Germán Hernández, productividad, hábitos, estilo de vida"
        />
        <meta name="author" content="Germán Hernández Mairal" />
      </Helmet>
    <div className="relative max-w-[90%] mx-auto overflow-hidden mb-20 mt-20 xs:mb-10 xs:mt-10 xs:px-4 md:mb-16 md:mt-16 lg:mb-24 lg:mt-24 xl:mb-32 xl:mt-32">
      {/* Botones de categorías */}
      <div className="flex justify-center mb-4">
        {Object.keys(playlists).map((category) => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            style={{
              backgroundColor: selectedCategory === category ? '#ffb300' : '#ffc107',
              color: 'white',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)', // Sombreado en el texto
            }}
            className={`px-4 py-2 mx-2 md:px-3 md:py-2 lg:px-5 lg:py-3 xl:px-8 xl:py-5 xs:px-2 xs:py-1 xs:mx-1 xs:text-sm rounded font-montserrat ${
              selectedCategory === category ? 'hover:bg-[#ffa000]' : 'hover:bg-[#ffb300]'
            } xl:text-2xl`}
          >
            {formatCategoryName(category)}
          </button>
        ))}
      </div>

      {/* Contenedor del grid */}
      <div className="flex items-center">
        {/* Flecha Izquierda */}
        <button
          onClick={prevSlide}
          className="absolute left-0 z-10 bg-[#ffc107] text-white rounded-full p-2 xs:p-1 md:p-3 lg:p-4 xl:p-5 hover:bg-[#ffb300] transition duration-300 shadow-md"
          style={{
            boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.3)', // Efecto de sombreado
          }}
          disabled={videos.length <= 1}
        >
          &#8592;
        </button>

        {/* Contenedor de videos */}
        <div className={`flex w-full transition-transform duration-300 ${displayedVideos.length < itemsToShow < 3 ? 'justify-center' : ''}`}>
          {displayedVideos.map((video, index) => (
            video && video.snippet ? (
              <div key={index} className="w-[33.33%] p-4 xs:w-full sm:w-1/2 md:w-[33.33%] xs:p-2 lg:w-[25%] xl:w-[21%] flex-shrink-0">
                <div className="bg-white shadow-lg rounded-lg p-4 xs:p-2 md:p-3 lg:p-4 xl:p-6 hover:shadow-2xl transition duration-300">
                  <div className="relative mb-4 xs:mb-2 lg:mb-6 xl:mb-8">
                    {video.snippet.thumbnails?.high ? (
                      <img
                        src={video.snippet.thumbnails.high.url}
                        alt={video.snippet.title}
                        className="w-full h-auto rounded-lg object-cover"
                        style={{ aspectRatio: '16/9' }}
                        loading="lazy" // Lazy loading para optimizar la carga
                      />
                    ) : (
                      <p className="text-center text-gray-500">No hay miniatura disponible</p>
                    )}
                  </div>
                  <h3 className="text-lg font-montserrat text-gray-800 mb-2 text-center md:text-base xl:text-xl">
                    {video.snippet.title}
                  </h3>
                  <p className="text-gray-600 text-sm mb-4 text-center md:text-base lg:text-base xl:text-xl">
                    {video.snippet.description ? video.snippet.description.substring(0, 100) : 'No hay descripción disponible'}...
                  </p>
                  <a
                    href={`https://www.youtube.com/watch?v=${video.snippet.resourceId?.videoId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-blue-500 text-white px-4 py-2 lg:px-6 lg:py-3 xl:px-8 xl:py-4 xs:px-3 xs:py-1 md:px-5 md:py-3 rounded-lg hover:bg-blue-600 transition duration-300 font-montserrat text-[1rem] xs:text-sm md:text-base lg:text-lg xl:text-xl block text-center"
                  >
                    Ver en YouTube
                  </a>
                </div>
              </div>
            ) : null
          ))}
        </div>

        {/* Flecha Derecha */}
        <button
          onClick={nextSlide}
          className="absolute right-0 z-10 bg-[#ffc107] text-white rounded-full p-2 xs:p-1 md:p-3 lg:p-4 xl:p-5 hover:bg-[#ffb300] transition duration-300 shadow-md"
          style={{
            boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.3)', // Efecto de sombreado
          }}
          disabled={videos.length <= 1}
        >
          &#8594;
        </button>
      </div>
    </div>
    </>
  );
};

export default VideoGrid;